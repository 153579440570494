import React, { useState } from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ContactusSection from "../components/mainpage/ContactusSection"
import FooterSection from "../components/mainpage/FooterSection"
import Menuzord from "../components/mainpage/Menuzord"
import { graphql } from "gatsby"
import ViewportBlock from "../components/viewportBlock"
import Seo from "../components/seo"
// styles
import * as styles from "../css/news.module.css"

const NewsItemPage = ({ data }) => { 
  const { title, content } = data.directus.articles[0]
  const [imgAvailable] = useState(data.directus.articles[0].image != null ? true : false)
  let image = ''
  if(data.directus.articles[0].image != null){
    image = getImage(data.directus.articles[0].image.imageFile)
  }
  function addZ(n){return n<10? '0'+n:''+n;}
  
  const date = new Date(data.directus.articles[0].date)
  const day = addZ(date.toLocaleDateString('ru-RU', {day: 'numeric'}))
  const month = date.toLocaleDateString('ru-RU', {month: 'long'}).substring(0,3)
  const year = date.toLocaleDateString('ru-RU', {year: 'numeric'})
  const finalDate = "Опубликовано "+month+" "+day+", "+year
  return (
    <>
      <Seo title={title}/>
      <Menuzord pageName="newsitem" light={true} />
      <section className={styles.newsSection}>
        <div className="container">
          <div className={styles.newsRow}>
            <ViewportBlock className='reveal'>
              {imgAvailable ? <div className={styles.image}><GatsbyImage image={image} alt={title}/></div> : "" }
              <div className={styles.datePublished}>
                {finalDate}
              </div>
              <h1 >
                {title}
              </h1>
              <div className={styles.articleSectionDescription}>
                <span dangerouslySetInnerHTML={{ __html: content}} />
              </div>
            </ViewportBlock>
          </div>
        </div>
      </section>
      <ContactusSection />
      <FooterSection />
    </>
  )
}

export default NewsItemPage

export const query = graphql`
  query PostQuery($url: String) {
    directus {
      articles(filter: {url: {_eq: $url}}) {
        id
        title
        content
        date
        url
        image {
          imageFile {
            childImageSharp {
              gatsbyImageData
            }
          }
          id
        }
      }
    }
  }
`